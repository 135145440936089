.footer {
    padding: 90px 70px;
    background: var(--color-black);
}
.footer-section p {
    max-width: 399px;
}
.footer-section:last-child {
    padding-left: 90px;
}
.footer-section h3, .footer-section h4 {
    margin: 0 0 20px;
}
.footer-section li {
    padding: 0 0 12px;
}
.sign-up-btn {
    padding: 5px 18px 5px 18px;
    border: 0.5px solid var(--color-white);
    background: transparent;
    color: var(--color-white);
    font-size: 11px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    cursor: pointer;;
}
.footer-section li a {
    letter-spacing: 0.05em;
    text-transform: lowercase;
}
.newsletter-signup {
    margin: 30px 0 0;
    display: flex;
    gap: 14px;
}
.newsletter-signup input {
    background: transparent;
    border: 0;
    border-bottom: .5px solid var(--color-white);
    padding: 5px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;
    min-width: 227px;
    color: #fff;
}
.newsletter-signup input::placeholder {
    color: var(--color-white);
}

/** responsive **/
@media (max-width:767px){
    .footer {
        padding: 60px 30px;
        gap: 40px;
    }
    .footer-section:last-child {
        padding-left: 0;
        margin: 40px 0 0;
    }
    .newsletter-signup input {
        min-width: 200px;
        border-radius: 0;
    }
    .footer-section h4 {
        font-size: 12px;
    }
    .footer-section h3, .footer-section h4 {
        font-weight: 600;
    }
    .newsletter-signup {
        margin: 60px 0 0;
        width: 100%;
    }
}
@media screen and (min-width:768px) and (max-width:1024px){
    .footer {
        padding: 70px 40px;
        gap: 30px;
    }
    .footer-section:last-child {
        padding-left: 0;
    }
    .footer-section p {
        max-width: 280px;
    }
}
@media screen and (min-width:1025px) and (max-width:1199px){
    .footer {
        padding: 70px 50px;
    }
    .footer-section:last-child {
        padding-left: 40px;
    }
}