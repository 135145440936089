.header {
    padding: 40px 80px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    position: absolute;
    width: 100%;
    z-index: 9;
    transition: all 0.3s ease-out;
}
.header-logo {
    z-index: 1;
    margin: auto;    
    padding: 0;
}
.header-logo img{
    max-width: 125px;
}
.border-right .header-logo {
    padding: 0;
}
.header.scrolled {
    position: fixed;
    top: 0;
    background: #00000033;
    padding: 15px 80px 15px;
    z-index: 99;
}
.header-scrolled .header-logo {
    max-height: 55px;
    position: fixed;
    top: 6px;
    z-index: 99;
    overflow: hidden;
}
.header-scrolled .header-logo img{
    max-width: 53px;
}
.header-logo-project img{
    max-width: 130px;
}
.header-scrolled .header-logo.header-logo-project {
    left: unset;
    transform: unset;
    top:11px;
}
.header-scrolled .header-logo.header-logo-project img {
    width: 90%;
}
.navbar-toggler span {
    line-height: unset;
    padding: 0;
    display: flex;
}
.header-right {
    align-items: center;
}
.navbar-toggler span img {
    width: 25px;
    z-index: 1;
}
.user-detail {
    display: flex;
    gap: 10px;
}
/** mega menu **/
.header-navbar {
    position: fixed;
    background: #fff;
    width: 100%;    
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    transform: translateY(-100%);
    transition: all 0.4s ease-out;
    padding: 20px 0 20px 85px;
    visibility: hidden;
    opacity: 0;
}
.header-navbar.show{
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
}
.menu-open .header-main:before {
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    background: #00000059;
    content: "";
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.mega-menu-botm{
    padding: 10px 0 0;
}
.mega-menu-tabs {
    width: 25%;    
}
.mega-menu-tab-title{    
    max-width: 320px;
    padding: 0 0 20px 0;
    position: relative;
}
.mega-menu-tab-title:after{
    width: 1px;
    height: 100%;
    background: #bfbfbf;
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: calc(50% + 30px);
    transform: translateX(50%);
    pointer-events: none;
}
.mega-menu-tab-panel {
    width: 75%;
}
.tab-panel-desc {
    display: flex;
    padding: 0 0;
    gap: 30px;
}
.tab-panel-desc-right {
    width: 100%;
}
.mega-menu-tab-title li {
    padding: 0 0 18px;
    letter-spacing: 0.2em;
    font-family: 'PP Neue Montreal Regular';
    position: relative;    
}
li.font-12.text-uppercase.hasSubmenu span {
    cursor: pointer;
}
.mega-menu-tab-title li a{
    padding: 4px 0;
    color: #000;
}
.mega-menu-tab-title li.active {
    font-weight: 600;
    cursor: auto;
}
.mega-menu-tab-title li.hasSubmenu:hover:after,
.mega-menu-tab-title li.hasSubmenu.active:after{
    content: '';
    position: absolute;
    left: 160px;
    top: 8px;
    width: 7px;
    height: 11px;
    background: url(../../assets/images/blackrightarrow.svg);
}
.mega-menu-tab-title li .sub-menu{
    margin: 0;
    padding: 0;    
    position: absolute;
    top: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;    
    z-index: 99;
}
.mega-menu-tab-title li:hover .sub-menu,
.mega-menu-tab-title li.active .sub-menu{
    visibility: visible;
    opacity: 1;
}
.mega-menu-tab-title li .sub-menu li{
    margin: 0 0 5px;
    padding: 0;
}
.mega-menu-tab-title li .sub-menu li a{
    font-size: 14px;
    text-transform: none;
}
.header-logo-menu {
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 20px 0 35px;
}
.header-logo-menu img{
    width: auto;
    max-height: 40px;
}
.tab-panel-desc-left a {
    color: #000;
    letter-spacing: 0.05em;
}
.tab-panel-desc-left span {
    display: block;
    padding: 15px 0 0;
    letter-spacing: 0.05em;
}
.tab-panel-desc-left:first-letter, .tab-panel-desc-left span:first-letter {
    text-transform: capitalize;
}
.mega-menu-slider .recent-featured-img img {
    position: unset;
}
.mega-menu-slider .recent-featured-img {
    height: 340px;
}
.mega-menu-slider .project-scale {
    font-size: 10px;
    line-height: 18px;
    letter-spacing: 0.05em;
}
.mega-menu-slider .home-recent-project-item .project-name {
    font-size: 10px;
    line-height: 18px;
}
.mega-menu-slider .home-recent-project-item span:not(:last-child) {
    border-right:0.36px solid #000000;
}
.mega-menu-slider .home-recent-project-item .project-name {
    border: 0;
}
.mega-menu-slider .project-name a{
    color: #000
}

/* .mega-menu-slider .slick-list{
    padding: 0 228px !important;
} */
.mega-menu-slider .slick-slide{
    padding: 0 5px;
}
.menu-open, .header-scrolled.menu-open {
    overflow: hidden;
}
.menu-open .navbar-toggler-icon img {
    filter: invert(1);
    margin: -13px 0 0px;
    width: auto;
}

/** responsive **/
@media (min-width:1401px){
    /* .mega-menu-slider .slick-list {
        padding: 0 202px 0 0  !important;
    } */
}

@media (max-width:1580px){

    .mega-menu-tabs{
        width: 28%;
    }
    .mega-menu-tab-panel{
        width: 72%;
    }    

}

@media (max-width:1480px){

    .mega-menu-tabs{
        width: 27.2%;
    }
    .mega-menu-tab-panel{
        width: 72.8%;
    }
    .mega-menu-tab-title{
        max-width: 300px;
    }

}

@media (max-width:1366px){

    .mega-menu-tabs{
        width: 30%;
    }
    .mega-menu-tab-panel{
        width: 70%;
    }

}  

@media (max-width:1240px){

    .mega-menu-tabs{
        width: 35%;
    }
    .mega-menu-tab-panel{
        width: 65%;
    }

} 

@media (max-width:767px){
      
    .header {
        padding: 30px 20px;
    }
    .header.scrolled {
        padding: 20px 20px;
    }
    .header-contact {
        display: none;
    }
    .mega-menu-tabs {
        width: 100%;
        padding: 0 20px;
    }
    .header-navbar {
        padding: 20px 0;
        height: 90%;
        overflow-x: auto;
    }
    .mega-menu-tab-title {
        margin: 0;
        border: 0;        
        display: flex;
        flex-direction: column;
        gap: 0 30px;
        width: 100%;
        flex-wrap: wrap;
    }
    .mega-menu-tab-title li {
        flex-basis: calc(50% - 15px);
    }
    .mega-menu-tab-panel {
        width: 100%;        
    }
    .mega-menu-tab-panel .tab-panel-desc-right{
        display: none;
    }
    .tab-panel-desc {
        gap: 15px;
        flex-wrap: wrap;
        padding-top: 5px;
        padding-left: 10px;
    }
    .tab-panel-desc-left {
        width: 100%;
    }
    .tab-panel-desc-right {
        width: 100%;
        padding: 0 20px 0 0;
    }        
    .mega-menu-tab-panel{
        display: none;
    }    
    
}

@media (max-width:480px){
    .header-scrolled .header-logo {
        position: unset;
    }
}

@media screen and (min-width:768px) and (max-width:1024px){
    .header, .header.scrolled {
        padding: 25px 30px 25px 40px;
    }
    .header-scrolled .header-logo.header-logo-project {
        top:25px;
    }
    .home-blog-slider h2 {
        margin: 10px 0 0;
    }
    .header-logo-menu {
        padding: 10px 0;
    }
    .mega-menu-botm {
        overflow-x: auto;
        height: 100%;
    }
    .header-navbar {
        padding: 20px 0 20px 30px;
        overflow-x: auto;
    }
    .mega-menu-tab-title {
        margin-right: 40px;
    }
    .tab-panel-desc {
        gap: 15px;
    }
    .tab-panel-desc-left {
        width: 30%;
    }    
    .mega-menu-tabs{
        width: 38%;
    }
    .mega-menu-tab-panel{
        width: 62%;
    }
    /* .mega-menu-slider .slick-list {
        padding: 0 32% !important;
    }  */
    .mega-menu-slider .recent-featured-img {
        height: 300px;
    }
}
@media screen and (min-width:1025px) and (max-width:1199px){
    .header, .header.scrolled {
        padding: 25px 40px 25px 50px;
    }
    .header-scrolled .header-logo.header-logo-project {
        top:25px;
    }
    /* .mega-menu-slider .slick-list {
        padding: 0 33% !important;
    }  */
    
}


@media (max-width:992px){

    .mega-menu-tabs{
        width: 45%;
    }
    .mega-menu-tab-panel{
        width: 55%;
    }
    .header-scrolled .header-logo {     
        top: 15px;        
    }    

}

@media (max-width:720px){

    .mega-menu-tabs{
        width: 360px;
    }
    .mega-menu-tab-title{
        max-width: 100%;
    }

}

@media (max-width:375px){
    
    .mega-menu-tabs{
        width: 100%;
    }
    .mega-menu-tab-panel{
        width: 100%;
    }
    .mega-menu-tab-title{
        max-width: 100%;
    }
    .mega-menu-tab-panel{
        display: none;
    }
    .mega-menu-tab-title:after {        
        left: 50%;        
    }
    .mega-menu-tab-title li.hasSubmenu:hover:after,
    .mega-menu-tab-title li.hasSubmenu.active:after{        
        left: 120px;        
    }
    .mega-menu-tab-title li .sub-menu{
        right: 35px;
    }

}