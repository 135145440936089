.modal {
    display: none; 
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: rgba(0, 0, 0, 0.75);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    justify-content: center;
  }
  .modal.show {display: flex;}
  .modal.show:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    -webkit-backdrop-filter: blur(7.5px);
    backdrop-filter: blur(7.5px);
    width: 100%;
    height: 100%;
}
  .modal-content {
    margin: auto;
    width: 100%;
    border-radius: 20px;
    background: #E2DED6;
    max-width: 1056px;
    position: relative;
    overflow: hidden;
  }
  .close {
    color: #fff;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    z-index: 1;
    cursor: pointer;
  }
  /* .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  } */
  .menu-modal-img {
    order: 2;
    flex: 0 0 40%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    padding: 61px 52px 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.menu-modal-content-desc {flex: 0 0 60%;}
.menu-list::-webkit-scrollbar {
  width: 5px;
  height: 4px;
  background: #f7f7f7;
}
.menu-list::-webkit-scrollbar-thumb {
background: #ccc; 
border-radius: 5px;
}
.menu-list {
    list-style: none;
    padding: 0;
    margin: 78px 0 60px;
    border-top: 1px solid rgba(0, 0, 0, 0.20);
    max-height: 640px;
    overflow-x: auto;
}
.menu-list li {
    padding: 20px 51px 20px 64px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.20);
    color: #181818;
    font-size: 13px;
    font-weight: 600;
    line-height: 35px;
    letter-spacing: 3.6px;
    cursor: pointer;
}
.menu-list li:after {
  content: "+";
  position: absolute;
  right: 51px;
  top: 27px;
  font-size: 20px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.80);
  letter-spacing: 0;
}
.submenu {list-style: none;padding: 0;margin: 18px 0 16px;}
.submenu li {border: 0;padding: 0 0 25px;}
.menu-items {max-width: 400px;}
.submenu li:last-child {
  padding-bottom: 0;
}
.menu-modal-img-botm .more-icon {
  padding: 8px 20px;
  background: rgba(255, 255, 255, 0.20);
  color: aliceblue;
  font-size: 13px;
  font-weight: 600;
  line-height: 35px; 
  letter-spacing: 3.9px;
}
.menu-modal-img-botm .more-icon img {filter: invert(1);}
.menu-modal-img-botm .status-sec {border: 0;padding: 0;margin: 0;}
.menu-modal-img-botm .menu-time {
  display: flex;
  flex-direction: column;
  color: #FFF;
  font-size: 16px;
  font-weight: 300;
  line-height: 30.625px; 
  letter-spacing: 0.8px;
}
.menu-modal-img-botm {
  align-items: start;
  z-index: 1;
}
.menu-modal-img:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 196px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.00) 100%);
  z-index: 0;
}
.menu-modal-img h2 {
  z-index: 1;
}
.menu-modal-img:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 432px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.00) 100%);
}
.modal-open {overflow: hidden;}

/* responsive */
@media (max-width:767px){
  .menu-modal-content-desc {
    flex: 0 0 100%;
    order: 2;
    /* max-height: 550px;
    overflow-y: auto; */
  }
  .menu-modal-imgmb {order: 1;flex: 0 0 100%;max-height: 203px;object-fit: cover;}
  .menu-modal-img{display: none;}
  .modal-content { margin: 90px auto auto;overflow: unset;border-radius: 20px 20px 0 0;        z-index: 99;}
  .menu-modal-content.d-flex {border-radius: 20px 20px 0 0;  overflow: hidden;}
  .close {top: -38px;right: 10px;}
  .menu-modal-img:before, .menu-modal-img:after {
      display: none;
  }
  .menu-details {padding: 37px 24px 25px 32px;flex-wrap: wrap;position: relative;gap: 8px;}
  .menu-modal-img-botm {width: 100%;}
  .menu-modal-img-botm .menu-time {flex-direction: row;color: #181818;gap: 10px;}
  .menu-modal-img-botm .more-icon {position: absolute;right: 24px;top: 37px;color: #181818;}
  .menu-modal-img-botm .more-icon img {filter: none;}
  .menu-list {margin-top: 0;}
  .menu-list > li {padding: 25px 45px 25px 33px;}
  .menu-list li:after {top: 32px;}
}
@media (max-width:480px){
  .menu-items {max-width: 250px;}
  .menu-details h2 {max-width: 220px;font-size: 25px;line-height: 30px;}
}
@media (max-width:375px){
  .menu-modal-img-botm .menu-time {flex-direction: column;gap: 5px;}
  /* .menu-modal-content-desc {
    max-height: 340px;
  } */
  .menu-details h2 {max-width: 190px;}
  .menu-items {max-width: 250px;}
}
@media screen and (min-width:768px) and (max-width:991px){
  .modal-content { width: 95%;}
  .menu-list {margin: 40px 0;}
  .modal {align-items: unset;}
  .menu-modal-img {padding: 40px 30px;}
  .menu-modal-content {height: 100%;overflow: auto;}
  .menu-items {max-width: 300px;}
  .menu-list > li {padding: 20px 40px;}
}
@media screen and (min-width:992px) and (max-width:1180px){
  .modal-content {width: 97%;}
  .menu-list {max-height: 534px;}
}